import React from 'react';
import AOS from 'aos';
AOS.init();

const whatido = () => {
    return(
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center"
                    data-aos="fade-up"
                    data-aos-delay="0"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                    >
                    <h2>What I Do</h2>
                    <div className="space-border"></div>
                </div>
            </div>
            <div className="spacer-single"></div>
            <div className="row">
                {/* <div className="col-lg-4"
                    data-aos="fade"
                    data-aos-delay="300"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                    >
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="icon_genius id-color-2"></i>
                            <div className="text">
                                <h3>Web app dev.</h3>
                                Delivering overaching web applications including frontend/backend and deployments.
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="col-lg-4"
                    data-aos="fade"
                    data-aos-delay="500"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                    >
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="icon_cog id-color-2"></i>
                            <div className="text">
                                <h3>Embedded FW dev.</h3>
                                Want to develop some code on an MCU but you don't know how to get it going or you need someone to review your implemention? You are at the right place!
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4" 
                    data-aos="fade"
                    data-aos-delay="400"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                    >
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="icon_lightbulb id-color-2"></i>
                            <div className="text">
                                <h3>Coaching</h3>
                                I used to have difficulties in avoiding burnouts, marking waypoints in my daily activities. I have turned my weaknesses into my best friends and I can help you cope with them too. 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4"
                    data-aos="fade"
                    data-aos-delay="500"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                    >
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="icon_document id-color-2"></i>
                            <div className="text">
                                <h3>Devising processes</h3>
                                Creating processes is easy. Creating effective processes which minimize the waste and focus on added value is a bit more challenging. 
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-lg-4"
                    data-aos="fade"
                    data-aos-delay="500"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                    >
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="icon_book id-color-2"></i>
                            <div className="text">
                                <h3>Resume review</h3>
                               You got stuck with your CV? You struggle finding what recruiters look for in your resume? You want some more professional design? Ping me!
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className="col-md-12 text-center mt-5">
          <h2
            data-aos="fade-up"
            data-aos-delay="0"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-once="true"
          >
            Developer toolbox
          </h2>
          <div
            className="space-border"
            data-aos="fade-up"
            data-aos-delay="20"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-once="true"
          ></div>
        </div>
          <div style={{display:"grid", gridTemplateColumns:"repeat(3,33%)", gridColumnGap: "10px"}}>
            <div className='m-2 p-2'>
              <h4 className="text-center">Embedded development</h4>
              <div className='text-center'>
                STM32, Espressif ESP32, C/C++, ST-LINK, FreeRTOS, J-LINK, SystemView, Rapid prototyping, Azure sphere, EMQx
              </div>
            </div>
			    <div className='m-2 p-2'>
              <h4 className="text-center">Web application development</h4>
              <div className='text-center'>
                ReactJS, Django, AWS, bootstrap, materialUI, (react-)redux, gunicorn, nginx, Twilio, SparkPost, Auth0
              </div>
            </div>
			    <div className='m-2 p-2 w-25%'>
              <h4 className="text-center">Other</h4>
              <div className='text-center'>
			  Eclipse, STM32Cube, Jira, Bitbucket, Confluence, Trello, git, gitlab, Microsoft Visual Studio, Azure cloud (IoT Hub, Device Provisioning Service, etc.),
			cmake, Altium Designer 2020, Odoo, Ubuntu/Kali Linux, docker
              </div>
            </div>
          </div>
        </div>
    );
}

export default whatido;