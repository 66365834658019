// import emailjs from '@emailjs/browser';
import emailjs from 'emailjs-com';
import { Parallax } from "react-parallax";
import AOS from 'aos';
import ReCAPTCHA from 'react-google-recaptcha';
import React, { useRef } from 'react';
AOS.init();




const image1 ="./img/background/5.jpg";
const Contact = () => {

    const refCaptcha = useRef();
  

    function sendEmail(e) {
      e.preventDefault();
      const success = document.getElementById("success");
      const button = document.getElementById("send_message");
      const failed = document.getElementById("failed");
      const token = refCaptcha.current.getValue();

      // Check if the ReCaptcha response token is empty
      if (!token) {
        alert("Please verify that you are not a robot");
        return;
      }

      emailjs
        .sendForm(
          "service_7mg44s5",
          "template_9kodr813",
          e.target,
          "kzuzcfuH9N9RFkHoi",
          {
            "g-recaptcha-response": token,
          }
        )
        .then(
          (result) => {
            console.log(result.text);
            success.classList.add("show");
            button.classList.add("show");
            failed.classList.remove("show");
          },
          (error) => {
            console.log(error.text);
            failed.classList.add("show");
          }
        );
    }

    return (
      <div className="section bg-top bg-bottom py-0">
        <Parallax className="py-5" bgImage={image1} strength={300}>
          <div className="py-5 position-relative">
            <div className="container">
              <div className="row">
                <div
                  className="col-md-12 text-center"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="0"
                  data-aos-duration="1000"
                  data-aos-easing="ease"
                >
                  <h2>Contact Me</h2>
                  <div className="space-border"></div>
                </div>
              </div>
              <div
                className="col-lg-8 offset-lg-2"
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-delay="200"
                data-aos-duration="1000"
                data-aos-easing="ease"
              >
                

                <div className="spacer-double"></div>
                <div className="row text-center">
                  <div
                    className="col text-center"
                    data-aos="fade-up"
                    data-aos-once="true"
                    data-aos-delay="0"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                  >
                    <div className="row text-center"></div>
                    <h1>dhatar94@gmail.com</h1>
                  </div>
                  {/* <div
                    className="col-md-4"
                    data-aos="fade-up"
                    data-aos-once="true"
                    data-aos-delay="400"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                  >
                    <div className="wm-1"></div>
                    <h6>Address</h6>
                    <p>Budapest, Hungary</p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </Parallax>
      </div>
    );
}

export default Contact;