import React from 'react';
import AOS from 'aos';
AOS.init();



const hero = () => {
	return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 text-center">
          <h2>My Resume</h2>
          <div className="space-border"></div>
        </div>
      </div>
      <div className="row gh-5">
        <div className="col-lg-6" data-aos="fade-up" data-aos-once="true">
          <div className="p-4">
            <h3 className="s_border">Experiences</h3>
            <ul className="d_timeline">
            <li className="d_timeline-item">
                <h3 className="d_timeline-title">2023 may. - to date</h3>
                <p className="d_timeline-text">
                  <span className="d_title">
                    Lead Electrical Engineer
                  </span>
                  <span className="d_company">Shrooly Ltd.</span>
                  <ul style={{ paddingLeft: "1px" }}>
                    <li>
                      {" "}
                      Developing ESP32 firmware for a mushroom growing device.
                    </li>
                    <li>
                      Automating workflows to streamline processes, improving efficiency
                      and reducing manual tasks.
                    </li>
                    <li>
                      Leading strategy planning and project management efforts.
                    </li>
                    <li>
                      Managing external working partners, fostering collaborative relationships.
                    </li>
                    <li>
                      Contributing to FW architecture development, ensuring scalability and
                      robustness of systems.
                    </li>
                  </ul>
                </p>
              </li>
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">2020 febr. - 2023 jul.</h3>
                <p className="d_timeline-text">
                  <span className="d_title">
                    Team Lead Electrical Engineering
                  </span>
                  <span className="d_company">Zippsafe AG</span>
                  <ul style={{ paddingLeft: "1px" }}>
                    <li>
                      {" "}
                      Delivering new SW solutions and improvements on an STM32
                      MCU for lockers using C++.
                    </li>
                    <li>
                      Managing electrical engineering and conveying weekly
                      status reports directly to the CTO.
                    </li>
                    <li>
                      Setting high level goals and maintaining a healthy backlog
                      for our products maximising the value delivered.
                    </li>
                    <li>
                      Design, optimization and supervision of electrical
                      engineering related operation processes.
                    </li>
                    <li>
                      Professional support for the international sales team and
                      as- sembly line in the production.
                    </li>
                    <li>
                      Coordinating various projects within the electrical
                      division.
                    </li>
                  </ul>
                </p>
              </li>
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">2020 nov. - 2021 febr.</h3>
                <p className="d_timeline-text">
                  <span className="d_title">
                    Firmware developer and advisor
                  </span>
                  <span className="d_company">Entremo Ltd.</span>
                  <ul style={{ paddingLeft: "1px" }}>
                    <li>
                      {" "}
                      Development of a SW for a health monitoring device with
                      the focus of battery and LED indication control.
                    </li>
                    <li>
                      Helped with architectural design considerations using
                      FreeRTOS.
                    </li>
                    <li>Reviewing HW design providing recommendations.</li>
                  </ul>
                </p>
              </li>
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">2017 jun. - 2020 febr.</h3>
                <p className="d_timeline-text">
                  <span className="d_title">Functional C/C++ SW developer</span>
                  <span className="d_company">Robert Bosch Hungary Ltd.</span>
                  <ul style={{ paddingLeft: "1px" }}>
                    <li>
                      Driver Assistance Systems - Predective Safety System
                    </li>
                    <li>
                      Familiarized myself with radar aided systems and their
                      opera- tion.
                    </li>
                    <li>
                      Took part in the development of various safety functions
                      as well as analyzed critical situations in order to filter
                      out malfunction- ing and to enhance the robustness of the
                      software.
                    </li>
                    <li>
                      Involved in the full flow of the process (according to V
                      model) starting with requirement engineering, software
                      development, and validation part.
                    </li>
                  </ul>
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-lg-6" data-aos="fade-up" data-aos-once="true">
          <div className="p-4">
            <h3 className="s_border">Education</h3>
            <ul className="d_timeline">
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">2018 - 2020</h3>
                <p className="d_timeline-text">
                  <span className="d_title">Master of Science</span>
                  <span className="d_company">
                    Budapest University of Techonology and Economics
                  </span>
                  As a part of my diploma project, an autonomously flying
                  quadcopter was designed including tasks such as adapting
                  Kalman-filter for disturbance mitigation, interpreting the
                  dynamics of the drone, human recognition involving deep
                  learning techniques.
                </p>
              </li>
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">2014 - 2018</h3>
                <p className="d_timeline-text">
                  <span className="d_title">Bachelor of Science</span>
                  <span className="d_company">
                    Budapest University of Techonology and Economics
                  </span>
                  Majored in Control Systems and Embedded Software Development.
                  My thesis work was connected to controlling slip of
                  wheels(ABS) of a car with sliding mode control.
                </p>
              </li>
              <li className="d_timeline-item">
                <h3 className="d_timeline-title">2013 - 2014</h3>
                <p className="d_timeline-text">
                  <span className="d_title">
                    Telecommunications Engineer - Vocational Course - OKJ{" "}
                  </span>
                  <span className="d_company">
                    Puskas Tivadar Technical Institution
                  </span>
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="spacer-double"></div>
      </div>
    </div>
  );
}

export default hero;