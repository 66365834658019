import React, { Component } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { settings } from './settingsnew';
import { createGlobalStyle } from 'styled-components';
import AOS from 'aos';
AOS.init();


const GlobalStyles = createGlobalStyle`
    body.hide{
          overflow-y: hidden; /* Hide vertical scrollbar */
          overflow-x: hidden; /* Hide horizontal scrollbar */
        }
`;

class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    return (
      <div {...props}></div>
    );
  }
}

const Blog= function() {
    /* lightbox1 */
    const [lighbx, setlighbx] = React.useState(false);
    const handleBtnClick = (): void => {
      setlighbx(!lighbx);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const handleBtnClickclose = (): void => {
      setlighbx(!lighbx);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };
	return(
		<div className="container">
            <GlobalStyles/>
            <div className="spacer-single"></div>
			<div className="row">
				<div className="col-md-12 text-center">
	                <h2>Recent Blog (More is yet to come)</h2>
	                <div className="space-border"></div>
	            </div>
			</div>
			<div className="row"
                data-aos="fade-up"
                data-aos-once="true"
                >
				<Slider {...settings}>
                <CustomSlide className='itm' index={1}>
                <div className="bloglist item">
                        <div className="post-content">
                            <div className="post-image">
                                <div className="de_modal" onClick={handleBtnClick}>
                                  <img alt="blogimg" src="./img/blog/1.jpg" className="grayscale"/>
                                </div>
                            </div>
                            <div className="post-text">
                                <h4 className="de_modal" onClick={handleBtnClick}>What are the signs of a great SCRUM team?</h4>
                                <p>The question mostly relates to a great (Scrum) team, however I firmly believe that a “simple” team should have the following/similar... </p>
                            </div>
                        </div>
                    </div>
                </CustomSlide>

                {/* <CustomSlide className='itm' index={2}>
                <div className="bloglist item">
                        <div className="post-content">
                            <div className="post-image">
                                <div className="de_modal" onClick={handleBtnClick}>
                                  <img alt="blogimg" src="./img/blog/2.jpg" className="grayscale"/>
                                </div>
                            </div>
                            <div className="post-text">
                                <h4 className="de_modal" onClick={handleBtnClick}>You need an MVP? Don't yearn for excellence!</h4>
                                <p>I saw people make a fuss about that they cannot deliver full-fledged and overaching SW for problems. Should this be of an issue? Pretty much doubt it...</p>
                            </div>
                        </div>
                    </div>
                </CustomSlide>

                <CustomSlide className='itm' index={3}>
                <div className="bloglist item">
                        <div className="post-content">
                            <div className="post-image">
                                <div className="de_modal" onClick={handleBtnClick}>
                                  <img alt="blogimg" src="./img/blog/3.jpg" className="grayscale"/>
                                </div>
                            </div>
                            <div className="post-text">
                                <h4 className="de_modal" onClick={handleBtnClick}>The Importance of Web Design</h4>
                                <p>Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...</p>
                            </div>
                        </div>
                    </div>
                </CustomSlide>

                <CustomSlide className='itm' index={4}>
                <div className="bloglist item">
                        <div className="post-content">
                            <div className="post-image">
                                <div className="de_modal" onClick={handleBtnClick}>
                                  <img alt="blogimg" src="./img/blog/4.jpg" className="grayscale"/>
                                </div>
                            </div>
                            <div className="post-text" onClick={handleBtnClick}>
                                <h4 className="de_modal">10 Web Design Tips From Experts</h4>
                                <p>Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...</p>
                            </div>
                        </div>
                    </div>
                </CustomSlide>
                <CustomSlide className='itm' index={5}>
                <div className="bloglist item">
                        <div className="post-content">
                            <div className="post-image">
                                <div className="de_modal" onClick={handleBtnClick}>
                                  <img alt="blogimg" src="./img/blog/3.jpg" className="grayscale"/>
                                </div>
                            </div>
                            <div className="post-text">
                                <h4 className="de_modal" onClick={handleBtnClick}>The Importance of Web Design</h4>
                                <p>Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...</p>
                            </div>
                        </div>
                    </div>
                </CustomSlide> */}

              </Slider>
			</div>

			  {/* lightbox1 */}
	          {lighbx && ( 
	          <div className="LightboxGal">
	            <div className="closeGal">
	                <button className="button-close" onClick={handleBtnClickclose}></button>
	            </div>
	            <div className="v-center w-100">
	              <div className="mainLightbox container">
	                <div className="row">
	                    <div className="col-md-8 offset-md-2">
                            <div className="blog-read">

                                <img alt="blogimg" src="./img/blog/big.jpg" className="img-fullwidth rounded"/>

                                <h2>What are the signs of a great SCRUM team?</h2>

                                <div className="post-text">
                                    <h5>Intro</h5>
                                    <p>The question mostly relates to a great (Scrum) team, however I firmly believe that a “simple” team should have the following/similar traits, competence and way of thinking too. Scrum as known by many is an agile framework facilitating delivering complex and adaptive problems. Scrum provides special events, artifacts that help ship software in an incremental and iterative way. This framework comes along with a team consisting of a Product Owner, a Scrum Master and Developers, a group of 6 to 10 people.</p>
                                    <h5>Embracing the values</h5>
                                    <p>I reckon, following all the general Scrum events blindly will not make any team great but a “Scrum  zombie”. Scrum is simple to understand but excelling at it is somewhat different. Many teams have fallen into this trap when trying to adapt Scrum in their organization with the eventual result of saying: “Scrum is not for us”. What makes the true difference when it comes down to becoming an outstanding team is when people in the team begin to understand the mindset and the values besides the workflow and see Scrum as a philosophy rather than a set of rules. Best practices on their own are not helpful if individuals don’t buy into the idea. Unparalleled self-organizing teams have a common vision clearly communicated and they work their ways towards it. Knowing the goal consistently articulated keeps the team engaged and laser-focused on the attainment at hand. In my humble opinion well-oiled Scrum team members are really high-achievers taking responsibility for their work. Responsibility gets them to be involved at all times and they can work together more effectively and transparently. </p>
                                    <h5>Make yourself heard</h5>
                                    <p>In many environments giving feedback in an honest manner is anything but advocated. The Scrum team has to have the guts to speak up for themselves and, if need be, say no to things that they don’t agree on collectively. This sometimes happens to be difficult and people have to be taught to raise their voice because if they don’t, they deprive the organization/project from the constructive feedback they could give by using e.g. a 4A feedback guideline firstly implemented at Netflix. Creation of a no blame culture is a contributing factor to reach this as well. Blaming the problem instead of the person establishes a milieu in which trust and respect can thrive on.</p>
                                    <h5>Palpable incremental results</h5>
                                    <p>Last but by no means least an effective Scrum team shall produce a potentially shippable product at the end of each sprint maximizing the value of the product enabling them to create a feedback loop through which they can gather and adapt to the needs and wants of the stakeholders. By doing so and acquiring empirical data, they can get to know themselves via iterations. After getting a firm grasp on their capabilities they will no longer cling onto estimations before planning as opposed to traditional waterfall methods. </p>
                                    <h5>Closing perception</h5>
                                    <p>As a one sentence summary, one of the best indicators of a great team is the people being happy and passionate about what they do. </p>
                                </div>

                            </div>
                        </div>
	                </div>
	              </div>
	            </div>
	          </div>
	          )}

		</div>
	);
}

export default Blog;